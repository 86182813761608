<template>
    <div>
        <button :class="buttonClasses"
                type="button"
                @click="openBooking()">
            {{i18nButtonText}}
        </button>
        <modal v-if="showModal" @close="showModal = false" :closeable="true" :small="true">
            <h2 class="mb-4" slot="header">
                {{i18nModalHeader}}
            </h2>
            <div slot="body">
                <iframe :src="bookingUrl"
                        frameBorder="0"
                        height="560px"
                        width="100%"
                        scrolling="yes"></iframe>
            </div>
        </modal>
    </div>
</template>

<script>
  import Modal from './Modal';

  export default {
    name: 'BookingButton',
    components: {
      Modal
    },
    props: [
      'i18nButtonText',
      'i18nModalHeader',
      'bookingUrl',
      'buttonClasses'
    ],
    data() {
      return {
        showModal: false
      }
    },
    mounted() {
    },
    methods: {
      openBooking() {
        if (this.$isMobile) {
          window.location.href = this.bookingUrl;
        } else {
          this.showModal = true
        }
      }
    }
  }
</script>

<style lang="scss">
    @import '../scss/variables';
</style>
