<template>
    <div class="location-input d-flex flex-row" :class="{'location-input--mobile': isFocusedOnMobile}">
        <div class="d-flex flex-row align-items-center">
            <icon class="location-input__icon" name="location" color="grey"></icon>
            <input class="location-input__input"
                   type="text"
                   ref="input"
                   v-model="location.address"
                   @focus="showOverlay()"
                   @blur="hideOverlay()"
                   :placeholder="i18nPlaceholder">
        </div>
    </div>
</template>

<script>
  import Icon from '@/components/Icon';
  import places from 'places.js/dist/cdn/places.js';

  export default {
    name: 'LocationInput',
    props: [
      'i18nPlaceholder',
      'value',
      'country'
    ],
    components: {
      Icon
    },
    data() {
      return {
        location: {
          address: '',
          lat: null,
          lng: null
        },
        isFocusedOnMobile: false
      }
    },
    mounted() {
      if (this.value) {
        this.location.address = typeof this.value === 'string' ? this.value : this.value.address;
      }

      const placesAutocomplete = places({
        appId: 'plIHLC6L1AGV',
        apiKey: '7898ccc6af8ccc1b06ae148bec4b3fee',
        container: this.$refs.input,
        countries: this.country ? [this.country] : [],
        templates: {
          value: function (suggestion) {
            if (suggestion.city) {
              return `${suggestion.name}, ${suggestion.city}`;
            }

            return suggestion.name;
          }
        }
      });

      placesAutocomplete.on('change', (event) => {
        const {city, name, latlng} = event.suggestion;
        this.location.lat = latlng.lat;
        this.location.lng = latlng.lng;
        this.location.address = city ? `${name}, ${city}` : name;
        this.changeValue();
        this.hideOverlay();
      });

      placesAutocomplete.on('clear', () => {
        this.location = {
          address: '',
          lat: null,
          lng: null
        };
        this.changeValue();
      });
    },
    methods: {
      changeValue() {
        this.$emit('input', this.location);
      },
      showOverlay() {
        if(!this.$isMobile) {
          return;
        }

        this.isFocusedOnMobile = true;
      },
      hideOverlay() {
        if(!this.$isMobile) {
          return;
        }

        this.isFocusedOnMobile = false;
      }
    }
  };
</script>

<style lang="scss">
    .ap-input-icon,
    .ap-footer {
        display: none;
    }
</style>

<style scoped lang="scss">
    @import "../scss/variables";
    @import "../scss/mixins";
    @import "../scss/button";
    @import "../../node_modules/bootstrap/scss/functions";
    @import "../../node_modules/bootstrap/scss/variables";
    @import "../../node_modules/bootstrap/scss/mixins";

    .location-input {
        width: 100%;
        padding-left: 4px;

        > div {
            width: 100%;
        }

        &__input {
            padding: 2px 4px;
            width: 100%;
            height: 40px;
            border: 0;
            color: $grey;

            &:focus,
            &:active {
                outline: none;
            }
        }

        &.is-danger {
            .location-input__input {
                color: $red;
            }
        }

        &--mobile {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 64px 16px !important;
            align-items: flex-start !important;
            background: #fff;
            border-radius: 0 !important;
        }
    }
</style>
