<template>
    <div class="cancellation-form">
        <h1 v-if="office">{{i18nHeadline.replace('%office%', office)}}</h1>
        <h1 v-else>{{i18nHeadlineNoOffice}}</h1>
        <div class="alert alert-danger"
             v-if="error">
            {{error}}
        </div>
        <div v-if="!success && !error">
            <form novalidate @submit.prevent="send()" v-if="appointments.length === 1">
                <p>{{i18nCancelOneNote.replace('%date%', date)}}</p>
                <div class="form-group">
                    <label for="reason">{{i18nReason}}</label>
                    <textarea class="form-control"
                              id="reason"
                              name="reason"
                              v-model="input.reason"></textarea>
                </div>
                <div class="text-center">
                    <button class="cta cta--primary"
                            type="submit"
                            :disabled="success">{{i18nSend}}
                    </button>
                </div>
            </form>
            <form novalidate @submit.prevent="send()" v-if="appointments.length > 1">
                <p>{{i18nCancelAllNote}}</p>
                <div class="form-group">
                    <div class="custom-control custom-radio">
                        <input class="custom-control-input"
                               type="radio"
                               id="cancelOne"
                               v-model="cancelMode"
                               value="one"
                               name="cancelOne">
                        <label class="custom-control-label text-dark-blue"
                               for="cancelOne">{{i18nCancelOne}}</label>
                    </div>
                    <div class="pl-4" v-if="cancelMode === 'one'">
                        <div class="custom-control custom-radio" v-for="appointment in appointments"
                             :key="appointment._id">
                            <input class="custom-control-input"
                                   type="radio"
                                   :id="`cancelAppointment-${appointment._id}`"
                                   :value="appointment._id"
                                   v-model="cancelAppointment"
                                   :name="`cancelAppointment-${appointment._id}`">
                            <label class="custom-control-label text-dark-blue"
                                   :for="`cancelAppointment-${appointment._id}`">
                                {{appointment.start | date('DD.MM.YYYY HH:mm')}}</label>
                        </div>
                    </div>
                    <div class="custom-control custom-radio">
                        <input class="custom-control-input"
                               type="radio"
                               id="cancelAll"
                               v-model="cancelMode"
                               value="all"
                               name="cancelAll">
                        <label class="custom-control-label text-dark-blue"
                               for="cancelAll">{{i18nCancelAll}}</label>
                    </div>
                </div>
                <div class="form-group">
                    <label for="reason">{{i18nReason}}</label>
                    <textarea class="form-control"
                              id="reason"
                              name="reason"
                              v-model="input.reason"></textarea>
                </div>
                <div class="text-center">
                    <button class="cta cta--primary"
                            type="submit"
                            :disabled="success">{{i18nSend}}
                    </button>
                </div>
            </form>
        </div>
        <div v-if="success && !error">
            <p>
                {{i18nSuccess}}
            </p>
        </div>
    </div>
</template>

<script>
  import AppointmentAPI from '@/api/appointment.js';
  import moment from 'moment';

  export default {
    name: 'CancellationForm',
    props: [
      'i18nHeadline',
      'i18nHeadlineNoOffice',
      'i18nSubtitle',
      'i18nSeries',
      'i18nCancelAll',
      'i18nCancelOne',
      'i18nCancelOneNote',
      'i18nCancelAllNote',
      'i18nReason',
      'i18nSend',
      'i18nSuccess',
      'i18nFail',
      'i18nInvalidToken',
      'i18nLimitExceeded',
      'i18nAppointmentAlreadyCancelled',
      'i18nCantFindAppointment',
      'i18nCantFindOffice',
      'i18nAppointmentInPast'
    ],
    data() {
      return {
        input: {
          reason: ''
        },
        appointments: [],
        office: null,
        success: false,
        date: '',
        error: null,
        cancelMode: 'one',
        cancelAppointment: null
      }
    },
    mounted() {
      AppointmentAPI
        .getCancellationInfo(T2G.token, T2G.appointment)
        .then(({ success, office, appointments }) => {
          if (success && appointments) {
            this.appointments = appointments;
            this.office = office;
            this.date = moment(appointments[0].start).format('DD.MM.YYYY HH:mm');

            if (appointments.length > 0) {
              this.cancelAppointment = this.appointments[0]._id;
            }
          }
        })
        .catch(({ error, office }) => {
          const ERROR_MAP = {
            invalid_token: this.i18nInvalidToken,
            limit_exceeded: this.i18nLimitExceeded,
            appointment_already_cancelled: this.i18nAppointmentAlreadyCancelled,
            cant_find_appointment: this.i18nCantFindAppointment,
            cant_find_office: this.i18nCantFindOffice,
            appointment_in_past: this.i18nAppointmentInPast
          };
          const translatedError = ERROR_MAP[error] || '';
          this.error = this.i18nFail.replace('%error%', translatedError);
          this.office = office;
        });
    },
    methods: {
      send() {
        const request = {
          token: T2G.token,
          appointment: this.cancelMode === 'one' ? this.cancelAppointment : T2G.appointment,
          reason: this.input.reason,
          type: this.cancelMode
        };

        AppointmentAPI
          .cancel(request)
          .then(() => {
            this.$toasted.show(this.i18nSuccess, {
              theme: 'primary',
              position: 'bottom-left'
            });
            this.success = true;
          })
          .catch((response) => {
            const errorMsg = this.i18nFail.replace('%error%', response.error.message);

            this.$toasted.show(errorMsg, {
              theme: 'bubble',
              position: 'bottom-left'
            });
          });
      }
    }
  };
</script>

<style scoped lang="scss">
    @import "../scss/variables";
    @import "../scss/mixins";
    @import "../../node_modules/bootstrap/scss/functions";
    @import "../../node_modules/bootstrap/scss/variables";
    @import "../../node_modules/bootstrap/scss/mixins";

    .cancellation-form {
        color: #fff;
    }

    h1 {
        font-size: 1.3rem;
        font-weight: bold;
    }

    p {
        font-size: 1rem;
    }

    .form-group {
        label {
            color: $dark-blue-2;
        }
    }
</style>
