<template>
  <modal v-if="showModal" @close="showModal = false" :closeable="true" :small="true">
    <h2 class="mb-4 text-center" slot="header" style="margin: 0 auto;">
      {{i18nModalHeader}}
    </h2>
    <div slot="body" class="text-center">
      {{i18nModalText}}
      <br><br>
      <a :href="buttonLink"
         class="cta cta--primary"
         style="margin: 0 auto;"
         role="button">{{i18nButtonText}}
      </a>
    </div>
  </modal>
</template>

<script>
  import Modal from './Modal';

  export default {
    name: 'OfferModal',
    components: {
      Modal
    },
    props: [
      'i18nButtonText',
      'i18nModalHeader',
      'buttonLink',
      'i18nModalText'
    ],
    data() {
      return {
        showModal: false
      }
    },
    mounted() {
      setTimeout(() => {
        this.showModal = true;
      }, 3000)
    },
    methods: {
    }
  }
</script>

<style lang="scss">
    @import '../scss/variables';
</style>
