<template>
    <div>
        <h1 class="register-form__headline" v-html="i18nHeaddline"></h1>
        <form novalidate @submit.prevent="register()">
            <div class="form-group" :class="getFieldClass('firstname')" v-if="!fullnameField">
                <label for="t2g_firstname">{{i18nFirstname}}*</label>
                <input class="form-control"
                       type="text"
                       id="t2g_firstname"
                       name="t2g_firstname"
                       v-validate="'required|min:2'"
                       v-model="input.firstname" required>
            </div>
            <div class="form-group" :class="getFieldClass('lastname')" v-if="!fullnameField">
                <label for="t2g_lastname">{{i18nLastname}}*</label>
                <input class="form-control"
                       type="text"
                       id="t2g_lastname"
                       name="t2g_lastname"
                       v-validate="'required|min:2'"
                       v-model="input.lastname" required>
            </div>
            <div class="form-group" :class="getFieldClass('fullname')" v-if="fullnameField">
                <label for="t2g_fullname">{{i18nFullname}}*</label>
                <input class="form-control"
                       type="text"
                       id="t2g_fullname"
                       name="t2g_fullname"
                       v-validate="'required|min:2'"
                       v-model="input.fullname" required>
            </div>
            <div class="form-group" :class="getFieldClass('email')">
                <label for="t2g_email">{{i18nEmail}}*</label>
                <input class="form-control"
                       type="email"
                       id="t2g_email"
                       name="t2g_email"
                       v-validate="'required|email'"
                       v-model="input.email" required>
            </div>
            <div class="form-group mb-4" :class="getFieldClass('password')">
                <label for="t2g_password">{{i18nPassword}}*</label>
                <input class="form-control"
                       type="password"
                       id="t2g_password"
                       name="t2g_password"
                       v-validate="'required|min:8'"
                       v-model="input.password" required>
                <small>{{i18nPasswordNote}}</small>
            </div>
            <div class="custom-control custom-checkbox mb-4">
                <input type="checkbox"
                       class="custom-control-input"
                       id="t2g_legalCheck"
                       v-model="input.legalCheck">
                <label class="custom-control-label text-muted check-label" for="t2g_legalCheck"
                       v-html="i18nTerms"></label>
            </div>
            <button class="cta cta--primary cta--lg" type="submit" :disabled="send">{{i18nSend}}</button>
        </form>
    </div>
</template>

<script>
  import OfficeAPI from '@/api/office.js';

  export default {
    name: 'RegisterForm',
    props: [
      'i18nHeaddline',
      'i18nFirstname',
      'i18nLastname',
      'i18nFullname',
      'i18nEmail',
      'i18nPassword',
      'i18nSend',
      'i18nTerms',
      'i18nPasswordNote',
      'i18nFieldRequired',
      'i18nCantRegister',
      'i18nRegisteredSuccessfully',
      'i18nCheckInput',
      'i18nUnknowError',
      'i18nEmailExistsError',
      'redirectLink',
      'industry',
      'fullnameField'
    ],
    data() {
      return {
        input: {
          firstname: '',
          lastname: '',
          email: '',
          password: '',
          legalCheck: false,
          fullname: ''
        },
        send: false,
        success: false
      }
    },
    // redirect to login
    methods: {
      getFieldClass(fieldName) {
        if (!this.fields[fieldName]) {
          return;
        }

        return {
          'is-danger': this.errors.has(fieldName),
          'touched': this.fields[fieldName].dirty
        }
      },
      register() {
        this.$validator
          .validateAll()
          .then((result) => {
            if (result) {
              this.send = true;

              if (this.fullnameField && this.input.fullname) {
                let nameParts = this.input.fullname.split(' ');

                if (nameParts && nameParts.length) {
                  this.input.firstname = nameParts[0];

                  if (nameParts.length > 1) {
                    this.input.lastname = nameParts[1];
                  }
                }
              }

              let request = {
                firstname: this.input.firstname,
                lastname: this.input.lastname,
                email: this.input.email,
                password: this.input.password,
                legalCheck: this.input.legalCheck
              };

              if (T2G) {
                if (T2G.country) {
                  request.countryCode = T2G.country;
                }
                if (T2G.lang) {
                  request.lang = T2G.lang;
                }
              }

              if (this.industry) {
                request.industry = this.industry;
              }

              OfficeAPI
                .register(request)
                .then(() => {
                  this.success = true;

                  if (window) {
                    if (window.ga) {
                      if (this.fullnameField) {
                        window.ga('send', 'event', 'service-provider', 'registered', `lp-${request.industry}-salons`);
                      } else {
                        window.ga('send', 'event', 'service-provider', 'registered', 'default-register-form');
                      }
                    }
                  }

                  if (this.fullnameField) {
                    this.$trackEvent(`LP_${request.industry.toUpperCase()}_CTA_LOS_GEHTS`, false, this.redirectLink);
                  } else {
                    this.$trackEvent('ANMELDEN_CTA_LOS_GEHTS', false, this.redirectLink);
                  }

                })
                .catch((error) => {
                  console.log(error);
                  const errorCode = error && error.error ? error.error : 'unknown';
                  const errorCodeMapping = {
                    unknown: this.i18nUnknowError,
                    email_exist: this.i18nEmailExistsError
                  };
                  const errorMessage = errorCodeMapping[errorCode] ? errorCodeMapping[errorCode] : errorCodeMapping.unknown;
                  this.success = false;
                  this.send = false;
                  this.$showErrorToast(this.i18nCantRegister, errorMessage);
                });
            } else {
              this.$showErrorToast(this.i18nCheckInput);
            }
          });
      }
    }
  };
</script>

<style lang="scss">
    @import "../scss/variables";
    @import "../scss/mixins";
    @import "../scss/form";
    @import "../../node_modules/bootstrap/scss/functions";
    @import "../../node_modules/bootstrap/scss/variables";
    @import "../../node_modules/bootstrap/scss/mixins";

    .background {
        &__container {
            position: relative;
        }
        &--dark-blue {
            position: absolute;
            z-index: 0;
            top: 0;

            @include media-breakpoint-up(md) {
                background: $dark-blue;
                min-height: 600px;
                width: calc(100% - 32px);
                border-radius: $border-radius;
                @include shadow();
            }
        }
    }

    .register {
        &__form {
            position: relative;
            z-index: 1;
            flex: 1 auto;
            padding: 48px;
            background: #fff;
            border-radius: 12px;
            @include shadow();

            @include media-breakpoint-up(lg) {
                width: 50%;
            }
        }

        &__list {
            position: relative;
            z-index: 1;
            flex: 1 auto;
            padding: 24px;
            margin: 0 16px 0 16px;

            @include media-breakpoint-up(lg) {
                width: 50%;
                padding: 48px;
            }

            > ul {
                height: 100%;
                list-style-type: none;
                padding-left: 0;

                > li {
                    position: relative;
                    padding-bottom: 24px;

                    > strong {
                        font-size: 1.2rem;
                    }

                    > p {
                        font-size: .9rem;
                        margin: 0;
                    }

                    + li {
                        padding-top: 24px;
                        border-top: 1px solid $grey-2;
                    }
                }
            }
        }
    }

    .thomas {
        height: auto;
        max-width: 150px;
        border-radius: 50%;
    }
</style>

<style scoped lang="scss">
    @import "../scss/variables";
    @import "../scss/mixins";
    @import "../../node_modules/bootstrap/scss/functions";
    @import "../../node_modules/bootstrap/scss/variables";
    @import "../../node_modules/bootstrap/scss/mixins";

    .register-form {
        &__headline {
            margin-bottom: 32px;
            font-size: 1.3rem;
            line-height: 1.6;
        }
    }
</style>
