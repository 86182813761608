<template>
    <div class="t2g-select">
        <button class="t2g-select__selected d-flex flex-row"
                @click.stop="toggle()"
                type="button"
                :class="{'t2g-select__selected--in': isOpen}">
            <div class="t2g-select__selected__text">
                <span v-if="selected">{{selected.label}}</span><span v-else>{{i18nPlaceholder}}</span>
            </div>
            <icon class="t2g-select__selected__icon" name="chevron-down" color="grey"></icon>
        </button>
        <select class="mobile-select"
                name="mobile-select"
                id="mobile-select"
                v-model="selected"
                v-if="$isMobile">
            <option :value="item"
                    v-for="item in items"
                    :key="item.value">{{item.label}}
            </option>
        </select>
        <div class="t2g-select__list" v-if="isOpen && !$isMobile" ref="list">
            <ul>
                <li v-for="item in items" :key="item.value">
                    <button class="t2g-select__list__item"
                            type="button"
                            @click="selected = item">{{item.label}}
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
  import Icon from '@/components/Icon';

  export default {
    name: 'T2gSelect',
    props: [
      'value',
      'items',
      'isRequired',
      'i18nPlaceholder'
    ],
    components: {
      Icon
    },
    data() {
      return {
        selected: {
          label: 'Bitte wählen Sie',
          value: null
        },
        isOpen: false,
        mobileSelectValue: null
      }
    },
    mounted() {
      let selectedItem;

      if (this.value) {
        selectedItem = this.items.find(item => item.value === this.value);

        if (selectedItem) {
          this.selected = selectedItem;
        } else {
          this.selected = null;
        }
      }
    },
    destroyed() {
      this._removeEventListener();
    },
    watch: {
      selected() {
        this.$emit('input', this.selected.value);
      }
    },
    methods: {
      _documentClicked() {
        if (this.isOpen) {
          this.toggle();
        }
      },
      _keyboardClicked(event) {
        if (event.keyCode === 27 && this.isOpen) {
          this.toggle();
        }
      },
      _initEventListener() {
        window.addEventListener('click', this._documentClicked);
        window.addEventListener('keydown', this._keyboardClicked)
      },
      _removeEventListener() {
        window.removeEventListener('click', this._documentClicked);
        window.removeEventListener('keydown', this._keyboardClicked);
      },
      toggle() {
        if (this.isOpen && this.$refs.list) {
          this.$refs.list.classList.remove('t2g-select__list--in');
          this._removeEventListener();
          setTimeout(() => {
            this.isOpen = false;
          }, 10);
        } else {
          this._initEventListener();
          this.isOpen = true;
          setTimeout(() => {
            this.$refs.list.classList.add('t2g-select__list--in');
          }, 100);
        }
      }
    }
  }
</script>


<style lang="scss" scoped>
    @import "../scss/variables";
    @import "../scss/mixins";
    @import "../scss/button";
    @import "../../node_modules/bootstrap/scss/functions";
    @import "../../node_modules/bootstrap/scss/variables";
    @import "../../node_modules/bootstrap/scss/mixins";

    .t2g-select {
        position: relative;
        width: 100%;

        &__selected {
            height: 32px;
            width: 100%;
            text-align: left;
            align-items: center;
            line-height: 32px;
            color: $grey;
            cursor: pointer;
            background: none;
            border: 0;

            @include media-breakpoint-up(sm) {
                height: 40px;
                line-height: 40px;
            }

            &__text {
                width: calc(100% - 24px);
            }

            &__icon {
                width: 24px;
                transition: transform .08s ease-out;
                transition-duration: .12s;
                will-change: transform;
            }

            &--in {
                .t2g-select__selected__icon {
                    transform: rotate(180deg);
                }
            }

            &:active,
            &:focus {
                outline: none;
            }
        }

        &__list {
            position: absolute;
            z-index: 1;
            right: 8px;
            left: 8px;
            background: #fff;
            will-change: opacity, transform;
            box-shadow: 0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08);
            transition: opacity .08s ease-out, transform .08s ease-out;
            transition-duration: .22s;
            transform: rotateX(10deg) skewX(3deg);
            opacity: 0;

            @include media-breakpoint-up(sm) {
                right: 8px;
                left: 0;
            }

            > ul {
                max-height: 215px;
                margin: 0;
                overflow-y: auto;
                position: relative;
                padding: 0;
                -webkit-tap-highlight-color: transparent;
            }

            &--in {
                transform: none;
                opacity: 1
            }

            &__item {
                width: 100%;
                padding: 4px 12px;
                background: none;
                border: none;
                display: inline-block;
                text-align: left;
            }
        }

        &.is-danger {
            .t2g-select__selected {
                color: $red;
            }
        }

        &--small {
            .t2g-select__list {
                left: 12px;
            }
        }
    }

    .mobile-select {
        position: absolute;
        top: 0;
        left: 0;
        height: 56px;
        width: 100%;
        background: transparent;
        background-image: none;
        border: 0;
        font-size: 0;
        box-shadow: none;
        -webkit-appearance: none;
    }
</style>
