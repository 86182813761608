<template>
    <div class="carousel">
        <swiper :options="carouselOptions"
                ref="carousel"
                v-if="slides.length > 0">
            <swiper-slide class="slide"
                          v-for="slide in slides"
                          :key="slide.image">
                <img class="slide__image img-fluid" :src="slide.image" :alt="slide.title">
                <div class="slide__text" v-if="slide.title">
                    <div>{{slide.title}}</div>
                </div>
            </swiper-slide>
            <button class="carousel__controls carousel__controls--left" slot="button-prev">
                <icon name="chevron-left" color="white"></icon>
            </button>
            <button class="carousel__controls carousel__controls--right" slot="button-next">
                <icon name="chevron-right" color="white"></icon>
            </button>
        </swiper>
    </div>
</template>

<script>
  import Icon from '@/components/Icon.vue';
  import {swiper, swiperSlide} from 'vue-awesome-swiper';
  import 'swiper/dist/css/swiper.css';

  export default {
    name: 'carousel',
    data() {
      return {
        carouselOptions: {
          slidesPerView: 1,
          slidesPerGroup: 1,
          spaceBetween: 8,
          loop: true,
          autoHeight: true,
          centeredSlides: true,
          navigation: {
            nextEl: '.carousel__controls--right',
            prevEl: '.carousel__controls--left'
          }
        },
        slides: []
      }
    },
    components: {
      swiper,
      swiperSlide,
      Icon
    },
    mounted() {
      let images;

      if (this.$slots) {
        images = Object.keys(this.$slots);

        if(images.length) {
          images.forEach((key) => {
            let imageAttrs = this.$slots[key][0].data.attrs;

            this.slides.push({
              image: imageAttrs.src,
              title: imageAttrs.alt
            });
          });
        }
      }
    },
    methods: {
    }
  };
</script>

<style lang="scss" scoped>
    .carousel {
        &__controls {
            position: absolute;
            top: 50%;
            z-index: 1;
            margin-top: -15px;
            height: 70px;
            width: 50px;
            background: rgba(0,0,0,0.5);
            border: 0;

            &:hover,
            &:focus,
            &:active {
                outline: 0;
                background: rgba(0,0,0,0.7);
                cursor: pointer;
            }

            &--left {
                left: 0;
            }

            &--right {
                right: 0;
            }
        }
    }

    .slide {
        text-align: center;

        &__image {
            max-height: 500px;
        }

        &__text {
            width: 100%;
            position: absolute;
            bottom: 4px;
            left: 0;
            color: #fff;
            text-align: center;

            > div {
                height: 32px;
                display: inline-block;
                background: rgba(0,0,0,0.7);
                padding: 4px 8px;
                overflow: hidden;
            }
        }
    }
</style>
