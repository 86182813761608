<template>
    <div class="navigation">
        <div class="d-none d-lg-block">
            <slot></slot>
        </div>
        <button class="navigation__toggle d-inline-block d-lg-none"
                type="button"
                v-if="!hideToggle"
                @click="toggleNav">
            <icon name="menu" color="primary"></icon>
        </button>
        <div class="navigation__modal">
            <modal v-if="isOpen" @close="isOpen = false" :closeable="true">
                <div slot="body">
                    <slot></slot>
                </div>
            </modal>
        </div>
    </div>
</template>

<script>
  import Icon from '@/components/Icon.vue';
  import Modal from '@/components/Modal.vue';

  export default {
    name: 'navigation',
    components: {
      Icon,
      Modal
    },
    data() {
      return {
        isOpen: false,
        hideToggle: false
      }
    },
    computed: {
      isMobile() {
        return this.$isMobile;
      }
    },
    mounted() {
      if (!this.$slots.default) {
        this.hideToggle = true;
      }
    },
    methods: {
      toggleNav() {
        this.isOpen = !this.isOpen;
      }
    }
  };
</script>

<style lang="scss" scoped>
    .navigation {
        &__toggle {
            height: 42px;
            width: 42px;
            position: fixed;
            right: 32px;
            top: 32px;
            padding: 8px;
            background: none;
            border: none;
            cursor: pointer;
            background: #fff;
            border-radius: 50%;
        }
    }
</style>
