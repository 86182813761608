<template>
    <form novalidate>
        <div class="search-bar-v d-flex flex-column">
            <t2g-select class="search-bar-v__select t2g-select--small flex-fill mb-3"
                        :class="{'is-danger': industryError}"
                        v-model="industry"
                        :i18n-placeholder="i18nIndustryPlaceholder"
                        :items="industries"></t2g-select>
            <location-input class="search-bar-v__location flex-fill"
                            :class="{'is-danger': locationError}"
                            :country="country"
                            v-model="location"
                            :i18n-placeholder="i18nLocationPlaceholder"></location-input>
            <button class="search-bar-v__button cta cta--secondary align-self-end"
                    type="button"
                    @click="search()">{{i18nSearch}}
            </button>
        </div>
    </form>
</template>

<script>
  import T2gSelect from '@/components/T2gSelect';
  import LocationInput from '@/components/LocationInput';

  export default {
    name: 'SearchBarV',
    components: {
      LocationInput,
      T2gSelect
    },
    props: [
      'i18nIndustryPlaceholder',
      'i18nLocationPlaceholder',
      'i18nSearch',
      'selectedIndustry',
      'selectedLocation',
      'searchUrl',
      'country'
    ],
    data() {
      return {
        industry: null,
        location: null,
        industryError: false,
        locationError: false
      }
    },
    computed: {
      isMobile() {
        return this.$isMobile;
      },
      industries() {
        return T2G.industries;
      }
    },
    created() {
      if (this.selectedIndustry) {
        this.industry = this.selectedIndustry;
      }

      if (this.selectedLocation) {
        this.location = this.location || {};
        this.location.address = this.selectedLocation;
      }
    },
    methods: {
      search() {
        const urlParams = [];
        let searchUrl = '';

        if (!this.industry) {
          this.industryError = true;
          return;
        } else {
          urlParams.push(this.industry);
        }

        if (!this.location) {
          this.locationError = true;
          return;
        } else {
          if (typeof this.location === 'string') {
            urlParams.push(this.location);
          } else if (this.location && this.location.address) {
            urlParams.push(this.location.address);
          }
        }

        // build search url
        searchUrl = `${this.searchUrl}/${urlParams.join('/')}`;

        if (this.location.lat && this.location.lng) {
          searchUrl += `?lat=${this.location.lat}&lng=${this.location.lng}`;
        }

        window.location = searchUrl;
      }
    }
  }
</script>


<style lang="scss" scoped>
    @import "../scss/variables";
    @import "../scss/mixins";
    @import "../scss/button";
    @import "../../node_modules/bootstrap/scss/functions";
    @import "../../node_modules/bootstrap/scss/variables";
    @import "../../node_modules/bootstrap/scss/mixins";

    .search-bar-v {

        &__select {
            padding: 4px 4px 4px 24px;
            margin-bottom: 16px;
            border-radius: 28px;
            background: #fff;
        }

        &__location {
            padding: 4px 4px 4px 24px;
            margin-bottom: 16px;
            border-radius: 28px;
            background: #fff;
        }

        &__button {
            width: 100%;
            padding: 16px;

            @include media-breakpoint-up(sm) {
                paddig: 8px 16px;
            }
        }
    }

    p {
        font-size: 1.2rem;
    }
</style>
