import Toasted from 'vue-toasted';

export default {
  install(Vue) {
    Vue.use(Toasted);
    Vue.prototype.$showSuccessToast = function (message) {
      this.$toasted.show(message, {
        theme: 'primary',
        position: 'bottom-left',
        duration: 40000
      });
    };
    Vue.prototype.$showErrorToast = function (message, error) {
      if (error) {
        message += ` (${error})`;
      }

      this.$toasted.show(message, {
        theme: 'bubble',
        position: 'bottom-left',
        duration: 40000
      });
    };
  }
};
