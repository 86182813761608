<template>
  <transition name="modal">
    <div class="modal-mask" @mousedown="closeModal()">
      <div class="modal-wrapper">
        <div class="modal-container" @mousedown="$event.stopPropagation()">
          <div class="modal-header">
            <slot name="header"></slot>
            <span class="modal-cancel"
                  @click="closeModal()"
                  v-if="closeable">
              <icon name="close" :width="32"></icon>
            </span>
          </div>
          <div class="modal-body">
            <slot name="body">
            </slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import Icon from './Icon';

  export default {
    name: 'modal',
    components: {
      Icon
    },
    data() {
      return {}
    },
    props: [
      'closeable'
    ],
    computed: {},
    methods: {
      closeModal() {
        if (this.closeable) {
          this.$emit('close');
        }
      }
    },
    mounted() {
      document.body.classList.add('modal-open');
    },
    destroyed() {
      document.body.classList.remove('modal-open');
    }
  }
</script>

<style lang="scss" scoped>
  @import '../scss/variables';

  .modal-open {
    overflow: hidden;
    padding-right: 17px;
  }

  .modal-footer {
    text-align: right;
  }

  .modal-mask {
    position: fixed;
    z-index: $z-index-modal;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .5);
    transition: opacity .3s ease;
    overflow-x: hidden;
    overflow-y: auto;

    .modal-container {
      border-radius: $border-radius;
      padding: 24px;

      .modal-footer,
      .modal-header {
        border: 0;
      }

      .modal-cancel {
        padding: 12px 12px 0 0;
        top: 0;
        right: 0;
        z-index: $z-index-modal-cancel;
      }

      .modal-cancel:hover {
        svg {
          path:first-of-type {
            fill: #000;
          }
        }
      }
    }
  }

  .modal-wrapper {
    max-width: 800px;
    margin: 1.75rem auto;
    padding: 0 16px;
  }

  .modal-container {
    position: relative;
    max-width: 900px;
    box-sizing: border-box;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .25s ease;
    font-family: Helvetica, Arial, sans-serif;
  }

  .modal-cancel {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }

  .modal-header {
    h3 {
      margin-top: 0;
      color: #000;
    }
  }

  .modal-body {
    padding-top: 16px;
  }

  .modal-default-button {
    float: right;
  }

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    transform: scale(1.05);
  }

  p {
    font-size: 1rem;
  }
</style>
