<template>
    <div class="products">
        <div class="row mb-40">
            <div class="col-12 d-flex flex-column align-items-center mb-4" :class="{'border-right': employeeSelector == 'true', 'col-sm-6': employeeSelector == 'true'}">
                <div class="payment-type-text h2 mb-3">{{i18nPaymentType}}:</div>
                <div class="btn-group btn-group-toggle btn-group-lg" data-toggle="buttons">
                    <label class="cta btn btn--left" :class="{'cta--primary': period === 'monthly'}">
                        <input type="radio"
                               name="paymentPeriod"
                               value="monthly"
                               v-model="period">
                        {{i18nMonthly}}
                    </label>
                    <label class="cta btn btn--right" :class="{'cta--primary': period === 'yearly'}">
                        <input type="radio"
                               name="paymentPeriod"
                               value="yearly"
                               autocomplete="off"
                               v-model="period">
                        {{i18nYearly}}
                    </label>
                </div>
            </div>
            <div class="col-12 col-sm-6 d-flex flex-column align-items-center mb-4" v-if="employeeSelector == 'true'">
                <div class="payment-type-text h2 mb-3">{{i18nEmployees}}:</div>
                <div class="input-group input-group-lg input-group--employee">
                    <div class="input-group-prepend">
                        <button class="btn cta cta--primary btn--left"
                                type="button"
                                @click="changeEmployees(-1)">-
                        </button>
                    </div>
                    <input class="form-control text-center" type="text" v-model="employees" readonly disabled>
                    <div class="input-group-append">
                        <button class="btn cta cta--primary btn--right"
                                type="button"
                                @click="changeEmployees(1)">+
                        </button>
                    </div>
                </div>
                <div class="alert alert-info text-center mt-2" v-if="employees >= 6">
                  Sie haben mehr als 5 Mitarbeiter? Gerne erstellen wir Ihnen ein individuelles Angebot.
                  <a href="javascript:$zopim.livechat.window.show();">Vertrieb kontaktieren</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import SubscriptionAPI from '../api/subscription';
  import debounce from 'lodash/debounce'

  export default {
    name: 'Products',
    data() {
      return {
        period: 'yearly',
        employees: 1,
        price: 0,
        discount: 0,
        isLoading: false
      }
    },
    watch: {
      period() {
        this.isLoading = true;
        this.getPrice();

        if (this.period === 'monthly') {
          this.$trackEvent('PREISE_ZAHLUNGSMETHODE_MONATLICH', true);
        } else {
          this.$trackEvent('PREISE_ZAHLUNGSMETHODE_JAEHRLICH', true);
        }
      },
      employees() {
        this.isLoading = true;
        this.getPrice();

        if (this.employees > 0) {
          this.$trackEvent('PREISE_MITARBEITER_WÄHLEN', true);
        } else {
          this.$trackEvent('PREISE_MITARBEITER_WÄHLEN', true);
        }
      }
    },
    mounted() {
      this.price = this.defaultPrice;
    },
    props: [
      'i18nYearly',
      'i18nMonthly',
      'i18nPaymentType',
      'i18nEmployees',
      'defaultPrice',
      'employeeSelector',
      'discountPrice'
    ],
    methods: {
      getPrice: debounce(function () {
        this.$root.$emit('t2g:price-loading');

        SubscriptionAPI
          .getPrice(this.employees, this.$cookie.get('t2g-utm_campaign'))
          .then((response) => {
            const prices = {}
            this.isLoading = false;
            Object
              .keys(response.prices)
              .forEach((priceVariant) => {
                if(response.prices[priceVariant].monthly) {
                  prices[priceVariant] = {
                    priceMonthly: response.prices[priceVariant][this.period].perMonth.toFixed(2),
                    discount: response.prices[priceVariant].discount[this.period].perMonth.toFixed(2)
                  }
                }
              })

            this.$root.$emit('t2g:price-changed', {
              price: prices
            });
          });
      }, 500),
      changeEmployees(amount) {
        this.employees += amount;
        this.employees = Math.max(1, this.employees);
        this.employees = Math.min(6, this.employees);
        this.getPrice()
      }
    },
  };
</script>

<style lang="scss">
    @import "../scss/variables";
    @import "../scss/text";
    @import "../scss/button";
</style>

<style scoped lang="scss">
    @import "../scss/variables";
    @import "../scss/mixins";
    @import "../../node_modules/bootstrap/scss/functions";
    @import "../../node_modules/bootstrap/scss/variables";
    @import "../../node_modules/bootstrap/scss/mixins";

    .mb-10 {
        margin-bottom: 64px;
    }

    .input-group {
        font-size: 1.2rem;

        &--employee {
            max-width: 150px;
        }

        .form-control {
            background: #fff;
            border-top: 1px solid $primary;
            border-bottom: 1px solid $primary;
            color: $primary;
        }
    }

    .btn {
        &--left {
            border-top-left-radius: 24px !important;
            border-bottom-left-radius: 24px !important;
        }

        &--right {
            border-top-right-radius: 24px !important;
            border-bottom-right-radius: 24px !important;
        }
    }

    .payment-type-text {
    }

</style>
