import Vue from 'vue';

export default {
  getPrice(employees = 1, campaign) {
    const query = campaign ? `?campaign=${campaign}&employees=${employees}` : `?employees=${employees}`;

    return Vue
      .http
      .request({
        url: `/v2/subscription/prices/${T2G.country}/${query}`,
        method: 'get',
        withCredentials: true
      })
      .then(response => response.data)
  }
};

