<template>
    <div class="newsletter-form container">
        <div class="row align-items-center">
            <div class="col-12 col-md-6">
                <div class="h1">{{i18nHeadline}}</div>
                <p>{{i18nSubtitle}}</p>
            </div>
            <div class="col-12 col-md-6">
                <div class="ig d-flex flex-row">
                    <input class="ig__input"
                           type="email"
                           name="email"
                           id="email"
                           :disabled="!inputEnabled"
                           v-model="email"
                           v-validate="'required|email'">
                    <button class="ig__button cta cta--primary align-self-end"
                            :disabled="!inputEnabled || !email || errors.any()"
                            type="button"
                            @click="subscribe()">{{i18nSubscribe}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import NewsletterAPI from '../api/newsletter';

  export default {
    name: 'NewsletterForm',
    props: [
      'i18nHeadline',
      'i18nSubtitle',
      'i18nSubscribe',
      'i18nApiSuccess',
      'i18nApiError'
    ],
    data() {
      return {
        email: '',
        inputEnabled: true
      }
    },
    methods: {
      subscribe() {
        this.$validator
          .validateAll()
          .then((result) => {
            if (result) {
              NewsletterAPI
                .subscribe(this.email)
                .then(() => {
                  this.inputEnabled = false;
                  this.$showSuccessToast(this.i18nApiSuccess);
                })
                .catch(() => {
                  this.$showSuccessToast(this.i18nApiError);
                });
            }
          });
      }
    }
  }
</script>

<style lang="scss" scoped>
    @import "../scss/variables";
    @import "../scss/mixins";
    @import "../scss/button";
    @import "../../node_modules/bootstrap/scss/functions";
    @import "../../node_modules/bootstrap/scss/variables";
    @import "../../node_modules/bootstrap/scss/mixins";

    .ig {
        height: 40px;
        border-radius: 20px;
        background: #fff;
        padding: 4px 4px 4px 24px;

        @include media-breakpoint-up(sm) {
            height: 56px;
            border-radius: 28px;
            background: #fff;
            padding: 8px 8px 8px 32px;
        }

        &__input {
            height: 32px;
            background: none;
            flex: 1 auto;
            border: none;
            color: $grey;
            font-size: .9rem;
            width: calc(100% - 100px);

            @include media-breakpoint-up(sm) {
                height: 40px;
            }

            &:focus {
                outline: none;
            }
        }

        &__button {
            height: 32px;
            line-height: 24px;
            padding: 4px 12px;

            @include media-breakpoint-up(sm) {
                height: 40px;
                line-height: 32px;
                padding: 4px 12px;
            }
        }
    }

    p {
        font-size: 1.2rem;
    }
</style>
