import Vue from 'vue';

export default {
  cancel(request) {
    return Vue
      .http
      .post('/v2/appointment/cancelByCustomer', request)
      .then(response => response.data)
  },
  getCancellationInfo(token, appointment) {
    return Vue
      .http
      .get(`/v2/appointment/${appointment}/${token}`)
      .then(response => response.data)
  }
};