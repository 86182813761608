// import App from './App.vue';
// import router from './router';
// import store from './store';
import 'babel-polyfill'
import BookingButton from '@/components/BookingButton.vue';
import CancellationForm from '@/components/CancellationForm.vue';
import Carousel from '@/components/Carousel.vue';
import HttpClient from '@/plugins/http-client';
import i18n from '@/plugins/i18n';
import TrackEventDirective from '@/plugins/track-event-directive';
import Icon from '@/components/Icon.vue';
import MobileCheck from '@/plugins/mobile-check';
import Navigation from '@/components/Navigation.vue';
import NewsletterForm from '@/components/NewsletterForm.vue';
import OfferModal from '@/components/OfferModal';
import CookieConsent from '@/components/CookieConsent.vue';
import Products from '@/components/Products.vue';
import RegisterForm from '@/components/RegisterForm.vue';
import PasswordForm from '@/components/PasswordForm.vue';
import TestCodeForm from '@/components/TestCodeForm.vue';
import EventObserver from '@/components/EventObserver.vue';
import SearchBar from '@/components/SearchBar.vue';
import SearchBarV from '@/components/SearchBarV.vue';
import toast from '@/plugins/toast';
import VeeValidate from 'vee-validate';
import VueCookie from 'vue-cookie';
import Vue from 'vue';
import Sticky from 'vue-sticky-directive'
import dateFilter from '@/plugins/dateFilter';
import 'bootstrap/dist/css/bootstrap.min.css';

import {swiper, swiperSlide} from 'vue-awesome-swiper';

require('./scss/base.scss');

// import './registerServiceWorker';

Vue.config.productionTip = false;

Vue.use(Sticky)
Vue.use(VeeValidate);
Vue.use(HttpClient);
Vue.use(i18n);
Vue.use(VueCookie);
Vue.use(MobileCheck);
Vue.use(TrackEventDirective);
Vue.use(toast);
Vue.use(VeeValidate, {
  classes: true
});

Vue.filter('date', dateFilter);

Vue.component('BookingButton', BookingButton);
Vue.component('CancellationForm', CancellationForm);
Vue.component('Carousel', Carousel);
Vue.component('CookieConsent', CookieConsent);
Vue.component('EventObserver', EventObserver);
Vue.component('Icon', Icon);
Vue.component('Navigation', Navigation);
Vue.component('NewsletterForm', NewsletterForm);
Vue.component('OfferModal', OfferModal);
Vue.component('PasswordForm', PasswordForm);
Vue.component('TestCodeForm', TestCodeForm);
Vue.component('Products', Products);
Vue.component('RegisterForm', RegisterForm);
Vue.component('SearchBar', SearchBar);
Vue.component('SearchBarV', SearchBarV);
Vue.component('SearchBarV', SearchBarV);
Vue.component('Swiper', swiper);
Vue.component('SwiperSlide', swiperSlide);

/* eslint-disable no-new */
new Vue({
  el: '#termin2go-web-app',
  // router,
  // store
  // render: h => h(App),
});
