<template>
  <div class="icon" v-bind:class="iconClass" aria-hidden="true">
    <svg v-if="name === 'refresh'"
         v-bind:height="width"
         v-bind:width="width"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 24 24">
      <path
          d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"/>
      <path d="M0 0h24v24H0z" fill="none"/>
    </svg>

    <svg v-if="name === 'alarm'"
         v-bind:height="width"
         v-bind:width="width"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 24 24">
      <path d="M0 0h24v24H0z" fill="none"/>
      <path
          d="M7.58 4.08L6.15 2.65C3.75 4.48 2.17 7.3 2.03 10.5h2c.15-2.65 1.51-4.97 3.55-6.42zm12.39 6.42h2c-.15-3.2-1.73-6.02-4.12-7.85l-1.42 1.43c2.02 1.45 3.39 3.77 3.54 6.42zM18 11c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2v-5zm-6 11c.14 0 .27-.01.4-.04.65-.14 1.18-.58 1.44-1.18.1-.24.15-.5.15-.78h-4c.01 1.1.9 2 2.01 2z"/>
    </svg>

    <svg v-if="name === 'menu'"
         v-bind:height="width"
         v-bind:width="width"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 24 24">
      <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/>
    </svg>

    <svg v-if="name === 'location'"
         v-bind:height="width"
         v-bind:width="width"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 24 24">
      <path
          d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
    </svg>

    <svg v-if="name === 'chevron-down'"
         v-bind:height="width"
         v-bind:width="width"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 24 24">
      <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/>
      <path fill="none" d="M0 0h24v24H0V0z"/>
    </svg>

    <svg v-if="name === 'chevron-left'"
         v-bind:height="width"
         v-bind:width="width"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 24 24">
      <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/>
      <path d="M0 0h24v24H0z" fill="none"/>
    </svg>

    <svg v-if="name === 'chevron-right'"
         v-bind:height="width"
         v-bind:width="width"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 24 24">
      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/>
      <path d="M0 0h24v24H0z" fill="none"/>
    </svg>

    <svg v-if="name === 'close'"
         v-bind:height="width"
         v-bind:width="width"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 24 24">
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
      <path d="M0 0h24v24H0z" fill="none"/>
    </svg>

    <svg v-if="name === 'email'"
         v-bind:height="width"
         v-bind:width="width"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 24 24">
      <path
          d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"/>
      <path d="M0 0h24v24H0z" fill="none"/>
    </svg>

    <svg v-if="name === 'phone'"
         v-bind:height="width"
         v-bind:width="width"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 24 24">
      <path d="M0 0h24v24H0z" fill="none"/>
      <path
          d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z"/>
    </svg>

    <svg v-if="name === 'facebook'"
         v-bind:height="width"
         v-bind:width="width"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 24 24">
      <rect fill="none" height="24" width="24"/><path d="M22,12c0-5.52-4.48-10-10-10S2,6.48,2,12c0,4.84,3.44,8.87,8,9.8V15H8v-3h2V9.5C10,7.57,11.57,6,13.5,6H16v3h-2 c-0.55,0-1,0.45-1,1v2h3v3h-3v6.95C18.05,21.45,22,17.19,22,12z"/>
    </svg>

    <svg v-if="name === 'link'"
         v-bind:height="width"
         v-bind:width="width"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 24 24">
      <path d="M0 0h24v24H0z" fill="none"/>
      <path
          d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"/>
    </svg>

    <svg v-if="name === 'check'"
         v-bind:height="width"
         v-bind:width="width"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 24 24">
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/>
    </svg>

    <svg v-if="name === 'download'"
         v-bind:height="width"
         v-bind:width="width"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 24 24">
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/>
    </svg>
  </div>
</template>

<script>
const PADDING_RATIO = 0.38;

export default {
  name: 'icon',
  props: {
    name: {
      type: String,
      required: true
    },
    color: {
      type: String
    },
    width: {
      type: Number,
      default: 24
    },
    rotating: {
      type: Boolean,
      default: false
    },
    circled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconClass() {
      return {
        'icon--primary': this.color === 'primary' || !this.color,
        'icon--secondary': this.color === 'secondary',
        'icon--white': this.color === 'white',
        'icon--grey': this.color === 'grey',
        'icon--dark-grey': this.color === 'dark-grey',
        'icon--dark-blue': this.color === 'dark-blue',
        'icon--blue': this.color === 'blue',
        'icon--red': this.color === 'red',
        'icon--rotating': this.rotating,
        'icon--circled': this.circled
      };
    }
  }
}
</script>

<style lang="scss">
@import '../scss/variables';

.icon {
  display: inline-block;
  font-size: 0;
  line-height: 0;
  vertical-align: middle;
  transition: all .3s;

  &--primary {
    fill: $primary;
    border-color: $primary;
  }

  &--secondary {
    fill: $secondary;
    border-color: $secondary;
  }

  &--white {
    fill: #fff;
    border-color: #fff;
  }

  &--grey {
    fill: $grey;
    border-color: $grey;
  }

  &--dark-grey {
    fill: $dark-grey;
    border-color: $dark-grey;
  }

  &--dark-blue {
    fill: $dark-blue;
    border-color: $dark-blue;
  }

  &--blue {
    fill: $blue;
    border-color: $blue;
  }

  &--red {
    fill: $red;
    border-color: $red;
  }

  &--rotating {
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  &--circled {
    background: $blue;
    border-style: solid;
    border-radius: 50%;
  }
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
