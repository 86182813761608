<template>
  <span>{{ value }}</span>
</template>

<script>
import _get from 'lodash/get'

export default {
  name: 'EventEmitter',
  data() {
    return {
      value: '',
      isLoading: false
    }
  },
  mounted() {
    this.value = this.default;

    this.$root.$on(this.doneEvent, data => {
      this.value = _get(data, this.property);
      this.isLoading = false;
    });

    this.$root.$on(this.loadingEvent, data => {
      this.isLoading = true;
    });
  },
  methods: {},
  props: [
    'doneEvent',
    'loadingEvent',
    'property',
    'default'
  ],
};
</script>
