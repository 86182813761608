<template>
  <form id="app"
        @submit="checkForm"
        :action="formAction"
        method="post">
    <div class="form-row" v-if="!deactivatePassword">
      <div class="form-group col-4" :class="getFieldClass('dayInput')">
        <label for="dayInput">Tag</label>
        <input class="form-control text-center"
               v-model="day"
               type="text"
               maxlength="2"
               placeholder="TT"
               @keypress="isNumber"
               @input="onDayInput"
               ref="dayInput"
               name="dayInput"
               v-validate="'required|min:1'"
               id="dayInput">
      </div>
      <div class="form-group col-4" :class="getFieldClass('monthInput')">
        <label for="monthInput">Monat</label>
        <input class="form-control text-center"
               v-model="month"
               type="text"
               maxlength="2"
               placeholder="MM"
               @keypress="isNumber"
               @input="onMonthInput"
               ref="monthInput"
               name="monthInput"
               v-validate="'required|min:1'"
               id="monthInput">
      </div>
      <div class="form-group col-4" :class="getFieldClass('yearInput')">
        <label for="yearInput">Jahr</label>
        <input class="form-control text-center"
               v-model="year"
               type="text"
               maxlength="4"
               placeholder="YYYY"
               @keypress="isNumber"
               @input="onYearInput"
               ref="yearInput"
               name="yearInput"
               v-validate="'required|min:4'"
               id="yearInput">
      </div>
    </div>
    <div class="error-message mb-3" id="error-message" v-if="hasError">{{ errorMessage }}</div>
    <button class="cta cta--primary cta--lg" type="submit">
      <span v-if="!deactivatePassword">Absenden</span>
      <span v-else>OK</span>
    </button>
  </form>
</template>

<script>
const moment = require('moment');

export default {
  name: 'PasswordForm',
  props: [
    'formAction',
    'hasError',
    'errorMessage',
    'deactivatePassword'
  ],
  data () {
    return {
      day: '',
      month: '',
      year: ''
    }
  },
  methods: {
    getFieldClass (fieldName) {
      if (!this.fields[fieldName]) {
        return;
      }

      return {
        'is-danger': this.errors.has(fieldName),
        'touched': this.fields[fieldName].dirty
      }
    },
    isNumber: function (evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async checkForm (event) {
      const valid = await this.$validator.validateAll();
      const dateValid = moment().date(this.day).month(parseInt(this.month, 10) - 1).year(this.year).isValid();

      if (!valid || !dateValid) {
        event.preventDefault();
      }
    },
    onDayInput () {
      if (this.day && this.day.length === 2) {
        this.$refs.monthInput.focus();
      }
    },
    onMonthInput () {
      if (this.month.length === 0) {
        this.$refs.dayInput.focus();
        this.$refs.dayInput.setSelectionRange(this.day.length, this.day.length);
      }

      if (this.month.length === 2) {
        this.$refs.yearInput.focus();
      }
    },
    onYearInput () {
      if (this.year.length === 0) {
        this.$refs.monthInput.focus();
        this.$refs.monthInput.setSelectionRange(this.month.length, this.month.length);
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../scss/variables";
@import "../scss/mixins";
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
</style>
