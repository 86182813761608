export default {
  install(Vue) {
    Vue.directive('trackEvent', {
      bind: function (el, binding) {
        const args = binding.arg.split(':');
        const eventName = args && args.length ? args[0].toUpperCase() : undefined;
        const sessionStorage = window.sessionStorage ? window.sessionStorage : undefined;
        const trackOnce = args[1] ? true : false;

        el.addEventListener('click', (event) => {
          const href = el.getAttribute('href');
          if (href) {
            if (window && window.amplitude && eventName) {
              if ((trackOnce && sessionStorage && !sessionStorage.getItem(eventName)) ||
                !trackOnce) {
                event.preventDefault();
                event.stopPropagation();

                if (trackOnce) {
                  sessionStorage.setItem(eventName, true);
                }

                if(T2G.amplitudeLoaded) {
                  window.amplitude.getInstance().logEvent(eventName, null, () => {
                    if (href.indexOf('javascript:') > -1) {
                      eval(href.split(':')[1]);
                    } else if (href.indexOf('mailto:') === -1) {
                      location.href = href;
                    }
                  });
                } else {
                  if (href.indexOf('javascript:') > -1) {
                    eval(href.split(':')[1]);
                  } else if (href.indexOf('mailto:') === -1) {
                    location.href = href;
                  }
                }
              }
            } else {
              if (href.indexOf('mailto:') === -1) {
                event.preventDefault();
                event.stopPropagation();

                if (href.indexOf('javascript:') > -1) {
                  eval(href.split(':')[1]);
                } else {
                  location.href = href;
                }
              }
            }
          } else if (window && window.amplitude && eventName) {
            if ((trackOnce && sessionStorage && !sessionStorage.getItem(eventName)) ||
              !trackOne) {
              if (trackOnce) {
                sessionStorage.setItem(eventName, true);
              }

              window.amplitude.getInstance().logEvent(eventName);
            }
          }

          if (window && window.ga && args.length > 1) {
            window.ga('send', 'event', eventName);
          }
        });
      }
    });

    Vue.prototype.$trackEvent = function (eventName, trackOnce, redirectHref) {
      if (window && window.ga) {
        window.ga('send', 'event', eventName);
      }

      eventName = eventName ? eventName.toUpperCase() : undefined;

      if (window && window.amplitude && window.amplitude.getInstance) {
        if ((trackOnce && sessionStorage && !sessionStorage.getItem(eventName)) ||
          !trackOnce) {
          if (trackOnce) {
            sessionStorage.setItem(eventName, true);
          }

          if(T2G.amplitudeLoaded) {
            window.amplitude.getInstance().logEvent(eventName, null, () => {
              if (redirectHref) {
                location.href = redirectHref;
              }
            });
          } else {
            if (redirectHref) {
              location.href = redirectHref;
            }
          }
        }
      } else if (redirectHref) {
        location.href = redirectHref;
      }
    };

  }
};


