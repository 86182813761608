import Vue from 'vue';

export default {
  subscribe(email) {
    return Vue
      .http
      .post('/v2/newsletter/subscribe', {email})
      .then(response => response.data)
  }
};
