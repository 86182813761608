<template>
    <div class="cookie-consent flex-column flex-md-row d-none"
         :class="{'d-flex': show, 'd-none': !show}"
         style="display: none;">
        <div class="cookie-consent__text mb-2 mb-md-0" v-html="i18nContent">
        </div>
        <div class="cookie-consent__button">
            <button type="button" @click="dismiss()">
                {{i18nButton}}
            </button>
        </div>
    </div>
</template>
<script>
  const COOKIE_NAME = 'cookieconsent_status';
  const COOKIE_VALUE = 'dismiss';
  const COOKIE_EXPIRE = 365;

  export default {
    name: 'CookieConset',
    data() {
      return {
        show: false
      }
    },
    mounted: function () {
      let self = this;

      setTimeout(() => {
        const cookieVal = this.$cookie.get(COOKIE_NAME);

        if (!cookieVal) {
          self.show = true;
        }
      }, 2000);
    },
    props: [
      'i18nContent',
      'i18nButton'
    ],
    methods: {
      dismiss() {
        this.$cookie.set(COOKIE_NAME, COOKIE_VALUE, {expires: COOKIE_EXPIRE});
        this.show = false;
      }
    }
  }
</script>

<style lang="scss">
    @import '../scss/variables';
    @import '../scss/mixins';
    @import '~bootstrap/scss/functions';
    @import '~bootstrap/scss/variables';
    @import '~bootstrap/scss/mixins';

    #cd_footnote {
        &.show-cookie-consent {
            padding-bottom: 180px;

            @include media-breakpoint-up(md) {
                padding-bottom: 88px;
            }

            @include media-breakpoint-up(lg) {
                padding-bottom: 72px;
            }
        }
    }

</style>

<style lang="scss" scoped>
    @import "../scss/variables";
    @import "../scss/mixins";

    .cookie-consent {
        padding: 1em 1.8em;
        position: fixed;
        overflow: hidden;
        font-size: 16px;
        line-height: 1.5em;
        flex-wrap: nowrap;
        z-index: $z-index-cookie-consent;
        background: $dark-blue;
        left: 0;
        bottom: 0;
        right: 0;
        color: #fff;
        align-items: baseline;
        justify-content: center;

        &__text {
            padding-right: 24px;
        }

        &__button {
            min-width: 140px;

            button {
                padding: .4em .8em;
                background: none;
                border: 2px solid #fff;
                color: #fff;
                width: 100%;
                display: block;
            }
        }
    }
</style>
