<template>
  <form id="app"
        @submit="checkForm"
        :action="formAction"
        method="post">
    <div class="form-row">
      <div class="form-group" :class="getFieldClass('codeInput')">
        <label for="codeInput">Code</label>
        <input class="form-control text-center"
               v-model="code"
               type="text"
               maxlength="10"
               name="codeInput"
               @keypress="isAlphanumeric"
               v-validate="'required|min:10'"
               id="codeInput">
      </div>
    </div>
    <div class="error-message mb-3" id="error-message" v-if="hasError">{{ errorMessage }}</div>
    <button class="cta cta--primary cta--lg" type="submit">Absenden</button>
  </form>
</template>

<script>
export default {
  name: 'TestCodeForm',
  props: [
    'formAction',
    'hasError',
    'errorMessage'
  ],
  data () {
    return {
      code: '',
    }
  },
  methods: {
    getFieldClass(fieldName) {
      if (!this.fields[fieldName]) {
        return;
      }

      return {
        'is-danger': this.errors.has(fieldName),
        'touched': this.fields[fieldName].dirty
      }
    },
    isAlphanumeric: function(evt) {
      // evt = (evt) ? evt : window.event;
      // var charCode = (evt.which) ? evt.which : evt.keyCode;
      // if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
      //   evt.preventDefault();
      // } else {
      //   return true;
      // }
      return true;
    },
    async checkForm (event) {
      const valid = await this.$validator.validateAll();

      if (!valid) {
        event.preventDefault();
      }
    },
  }
};
</script>

<style scoped lang="scss">
@import "../scss/variables";
@import "../scss/mixins";
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

.form-control {
  text-transform: uppercase;
}
</style>
