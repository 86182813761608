import axios from 'axios';

axios.defaults.withCredentials = true;

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE,
  withCredentials: true
});

httpClient.interceptors.response.use((response) => {
  return response;
}, function (error) {
  return Promise.reject(error.response.data);
});

httpClient.defaults.headers.post['Accept-Language'] = T2G && T2G.lang ? T2G.lang : '*';

export default {
  install(Vue) {
    Vue.http = httpClient;
    Vue.prototype.$http = httpClient;
  }
};
